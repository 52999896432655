.galleryImg {
  width: 100%;
  height: auto;
  padding: 10px;
}
.galleryGreenCard {
  margin: 40px 0;
  padding: 0 20px;
  width: 400px;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: white;
}
.galleryGreenCardText {
  font-size: 20px;
}
.galleryConatiner {
  padding: 180px 50px 60px 50px;
  background-color: #e5e5e5;
}
@media (max-width: 900px) {
  .galleryConatiner {
    padding: 180px 20px 60px 20px;
  }
}
