.navBar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #e5e5e5;
  color: #6a8b82;
}
.navMenuContainer {
  display: none;
  position: fixed;
  opacity: 0;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 20vh 0;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.navMenuContainer button {
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: fixed;
  right: 10%;
  top: 10%;
}
.navMenuContainer a {
  color: #6a8b82;
  font-size: 24px;
}
.logoGreen {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 20;
  cursor: pointer;
}
.appear {
  animation: appear 0.4s forwards;
}
.disappear {
  animation: disappear 0.4s forwards;
}

.navAppear {
  animation: appear 0.5s forwards;
}
.navDisappear {
  animation: disappear 0.5s forwards;
}
.logoBlack {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  animation: disappear 1s 2s forwards, toBack 0.1s 2s forwards;
}
.logosContainer {
  position: relative;
  height: 110px;
  width: 110px;
}
.social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navContainer {
  /*max-width: 1600px;*/
  margin: 0 auto;
  padding: 0 7%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prenota {
  cursor: pointer;
  color: #6a8b82;
  font-size: 18px;
  margin: 0;
  padding: 0;
  opacity: 0;
  animation: appear 1s 2s forwards;
}
.menuButton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -35px;
  font-size: 20px;
  padding: 0;
  opacity: 0;
}
.social i {
  padding: 0;
  font-size: 24px;
  margin: 0 18px;
  opacity: 0;
  animation: appear 1s 2s forwards;
}
.social a {
  color: #6a8b82;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes toBack {
  from {
    z-index: 30;
  }
  to {
    z-index: 10;
  }
}
@media (max-width: 750px) {
  .navContainer {
    height: 120px;
  }
  .prenota {
    font-size: 20px;
  }
  .logosContainer {
    height: 90px;
    width: 90px;
  }
  .menuButton {
    margin-top: -17px;
    margin-left: -29px;
    font-size: 20px;
  }
  .social i {
    font-size: 20px;
    margin: 0 15px;
  }
}
@media (max-width: 500px) {
  .navContainer {
    height: 100px;
  }
  .prenota {
    font-size: 15px;
  }
  .logosContainer {
    height: 70px;
    width: 70px;
  }
  .menuButton {
    margin-top: -12px;
    margin-left: -21px;
    font-size: 15px;
  }
  .social i {
    font-size: 15px;
    margin: 0 11px;
  }
}
.margine{
  margin-left: -150px;
}


/* Media for the left links disappear in mobile */
@media (max-width: 768px) { /* Adjust the width to match your tablet breakpoint */
  .res {
    display: none;
  }
  .margine{
    margin-left: 0;
  }
}


/* Media for the menu links disappear in desktop */
@media (min-width: 769px) { /* Adjust the width to match your desktop breakpoint */
  .desktop-only {
    display: none; /* Or any other display property you prefer */
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: block;
  }
}
