.contattiTextContainer {
  width: 80%;
}
.contattiBody {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 80px;
  padding-top: 200px;
}
.contattiTextContainer {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.contattiInfoText {
  font-size: 20px;
  color: #272727;
}
.ant-modal-mask {
  z-index: 99999;
}
.ant-modal-wrap {
  z-index: 99999;
}
.closeModalButtonCustom {
  padding: 7px 20px;
  background-color: transparent;
  border: 1px solid #272727;
  border-radius: 5px;
}
.con{
  font-size: 3rem;
}
@media (max-width: 1000px) {
  .contattiBody {
    flex-direction: column-reverse;
  }
  .contattiTextContainer {
    margin-top: 70px;
  }
}
@media (max-width: 750px) {
  .contattiBody {
    padding: 160px 20px 20px 20px;
  }
  #contattiMap {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .contattiBody {
    padding: 160px 20px 20px 20px;
  }
  .contattiTextContainer {
    margin-top: 70px;
  }
  .contattiInfoText {
    font-size: 4vw;
  }
  .contattiTextContainer {
    min-height: unset;
  }
}
@media (max-width: 400px) {
  .contattiInfoText {
    font-size: 5vw;
  }
}
