.footer {
  width: 100%;
  position: relative;
  z-index: 40;
  display: flex;
  justify-content: space-around;
  background-color: #272727;
  padding: 20px;
  color: #e5e5e5;
}
.footer p {
  font-size: 15px;
}
.footerMenu {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.footerLogo {
  height: auto;

  width: 120px;
}
.footerUIContainer {
  margin-top: 30px;
}
.footerUI {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.footerContacts {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.footerSocial {
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.footerSocial i {
  font-size: 20px;
}
.footerSocial a {
  color: white;
}

@media (max-width: 1100px) {
  .footer {
    flex-wrap: wrap;
  }
  .footerMenu {
    width: 60%;
  }
}
@media (max-width: 750px) {
  .footer {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
  .footerMenu {
    width: unset;
  }
  .footerContacts {
    margin-top: 50px;
  }
}
