.sectionUno {
  height: 100vh;
  width: 100%;
  background-color: #e5e5e5;
}
.scrollContainer {
  width: 100%;
  position: fixed;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 80%;
  color: white;
  animation: loop 2s infinite alternate ease-in-out;
}
.scrollText {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
}
.scrollIcon {
  font-size: 30px;
}

@keyframes loop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

@media (max-width: 1400px) {
  .scrollContainer {
    top: 49vw;
  }
  .sectionUno {
    height: 72vw;
  }
}

@media (max-width: 750px) {
  .scrollContainer {
    top: 54vw;
  }
  .scrollText {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
  }
  .scrollIcon {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .sectionUno {
    height: 83vw;
  }
}
