.homeBackground {
  background-color: #6a8b82;
}
.atterraggio {
  padding: 0 7%;
  position: fixed;
  top: 150px;
  height: 750px;
  overflow: hidden;
  z-index: 25;
  animation: disappear 1s 2s forwards;
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 750px) {
  .homeCarousel {
    top: 130px !important;
  }
  .atterraggio {
    top: 130px;
  }
}
@media (max-width: 500px) {
  .homeCarousel {
    top: 110px !important;
  }
  .atterraggio {
    top: 110px;
  }
}
