.sectionDue {
  width: 100%;
  position: relative;
  z-index: 40;
}
.whiteContainer {
  background-color: white;
  text-align: center;
  padding: 50px 0;
}
h2 {
  font-size: 65px !important;
  font-weight: 200 !important;
}
.whiteSub {
  font-size: 20px;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.ballsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
.whiteBall {
  height: 120px;
  width: 120px;
  margin: 40px;
  border-radius: 50%;
  background-color: #6a8b82;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.whiteBall:hover {
  background-color: #a1b2ad;
}
.ballText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.transparentContainer {
  width: 100%;
  height: 50vh;
}
.greenContainer {
  background-color: #6a8b82;
  text-align: center;
  padding: 50px 0;
}
.greenBall {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  cursor: pointer;
}
.greenBall:hover {
  background-color: #a1b2ad;
}
.greenballText {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #6a8b82;
}
.greenSub {
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 30px;
  color: white;
}
.linkContainer {
  background-color: #e5e5e5;
  text-align: center;
  padding: 50px 0;
}

@media (max-width: 750px) {
  .ballsContainer {
    flex-direction: column;
  }
  h2 {
    font-size: 6vw !important;
  }
  .whiteSub {
    font-size: 3.5vw;
    padding: 0 30px;
  }
  .greenSub {
    font-size: 3.5vw;
    padding: 0 30px;
  }
  .transparentContainer {
    height: 42vh;
  }
}

@media (max-width: 500px) {
  h2 {
    font-size: 30px !important;
    padding: 0 10px;
  }
  .whiteSub {
    font-size: 20px;
  }
  .greenSub {
    font-size: 20px;
  }
  .transparentContainer {
    height: 32vh;
  }
}
