.menuIntro {
  background-color: #e5e5e5;
}
.menuIntroTextContainer {
  text-align: center;
  padding: 50px 0;
}
.menuGreenSection {
  background-color: #6a8b82;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 80px;
}
.greenCardTitle {
  color: white;
  font-size: 56px;
}
.menuGreenCard {
  margin: 40px 0;
  padding: 0 20px;
  width: 400px;
  min-height: 460px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: white;
}
.menuGreenCardText {
  font-size: 18px;
}
.menuGreySection {
  background-color: #e5e5e5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 40px;
}
.menuWhiteSection {
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 40px;
}
@media (max-width: 750px) {
  .menuCarousel {
    margin-top: 120px !important;
  }
}
@media (max-width: 500px) {
  .menuCarousel {
    margin-top: 100px !important;
  }
  .menuGreenSection {
    padding: 20px;
  }
}
