.side {
  margin-top: 150px;
  padding: 50px 70px 80px 70px;
  width: 50%;
  min-height: 1050px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}
.squadraIMG {
  width: 100%;
  height: auto;
}
.squadraContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.squadraRightSide {
  background-color: #6a8b82;
  color: white;
}
.squadraText {
  font-size: 18px;
}

@media (max-width: 890px) {
  .squadraContainer {
    flex-direction: column;
  }
  .side {
    width: 100%;
    min-height: unset;
  }
  .squadraRightSide {
    margin-top: 0;
  }
}
@media (max-width: 750px) {
  .squadraLeftSide {
    margin-top: 120px;
  }
}

@media (max-width: 500px) {
  .squadraLeftSide {
    margin-top: 100px;
  }
  .side {
    padding: 50px 50px 70px 50px;
  }
}
