@font-face {
 font-family: "Giorgio XLarge Web";
  src: local('Giorgio XLarge Web'), url("./fonts/GiorgioXL-Regular-Web.woff2") format('woff2');
}


body {
  margin: 0;
  font-family: serif;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none !important;
}
a {
  text-decoration: none !important;
}
a:hover {
  color: unset !important;
  text-decoration: none !important;
}
hr {
  border-color: white !important;
}

.giorgio {
  font-family: 'Giorgio XLarge Web', serif !important;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

